import { useRef, useState, useCallback } from 'react'

import { isUndefined, warnOnce, isFunction } from 'lib/util'
import { isProduction } from 'config/appConfig'

/**
 * Hook that behaves like useState but is controllable (e.g from parent).
 *
 * Based on the behavior of input elements.
 * Found an existing implementation with examples:
 * - https://github.com/alirezamirian/react-use-controllable-state
 * - https://medium.com/quick-code/writing-ui-components-with-optionally-controllable-state-86e396a6f1ec
 *
 * - rewrite to make setValue depending on onChange only. see
 *   https://github.com/adobe/react-spectrum/blob/main/packages/%40react-stately/utils/src/useControlledState.ts
 *   for inspiration
 */
export const useControllableState = (initialValue, value, onChange) => {
  const [localValue, setLocalValue] = useState(value ?? initialValue)

  const valueRef = useRef(localValue)

  const isControlled = useRef(!isUndefined(value))
  const externalValSet = !isUndefined(value)

  if (!isProduction && isControlled.current !== externalValSet) {
    const from = isControlled.current ? 'controlled' : 'uncontrolled'
    const to = isControlled.current ? 'uncontrolled' : 'controlled'
    const msg = `useControllableState: mode was changed from ${from} to ${to}`
    warnOnce('useControllableState', msg).then(() => console.trace())
  }

  const setValue = useCallback(
    (nextValue, changeEmitter) => {
      let callOnChange = false
      let onChangeValue = nextValue
      if (!isControlled.current) {
        // setLocalValue(nextValue)
        // callOnChange = true
        if (isFunction(nextValue)) {
          const interceptedValue = nextValue(valueRef.current)
          if (!Object.is(valueRef.current, interceptedValue)) {
            setLocalValue(interceptedValue)
            callOnChange = true
            onChangeValue = interceptedValue
          }
        } else {
          setLocalValue(nextValue)
          callOnChange = true
        }
      } else {
        if (isFunction(nextValue)) {
          const interceptedValue = nextValue(valueRef.current)
          if (!Object.is(valueRef.current, interceptedValue)) {
            callOnChange = true
            onChangeValue = interceptedValue
          }
        } else {
          callOnChange = true
        }
      }
      callOnChange && isFunction(onChange) && onChange(onChangeValue, changeEmitter)
    },
    [onChange]
  )

  if (!isControlled.current) {
    value = localValue
  }
  valueRef.current = value

  return [value, setValue, isControlled.current]
}
