import React from 'react'
import styled from 'styled-components'

import { array, number, oneOfType, string } from 'prop-types'
import { useArrayValueByBreakpoint } from 'lib/hooks'

const excludeProps = ['bg']

const StyledComponent = styled.td.withConfig({
  shouldForwardProp: (props) => !excludeProps.includes(props),
})`
  span {
    display: block;
    aspect-ratio: 1/1;
    margin-right: 5px;
    width: ${(props) => props.width};
  }
`

export const ColorCell = ({ color, width }) => {
  const widthValue = useArrayValueByBreakpoint(width)
  return (
    <StyledComponent width={widthValue}>
      <span style={{ backgroundColor: color, width: widthValue }}></span>
    </StyledComponent>
  )
}

ColorCell.propTypes = {
  color: string,
  width: oneOfType([string, number, array]),
}

ColorCell.defaultProps = {
  color: 'violet',
  width: ['18px', null, '21px', null, '21px'],
}
