export const getCityStructure = () => ({
  mapLayer: {
    opacity: 0.5,
    fillOpacity: 0.6,
    opacityZoomOut: 0.75,
    fillOpacityZoomOut: 0.85,
  },
  legend: {
    opacity: 0.6,
  },
})
