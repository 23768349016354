import 'regenerator-runtime/runtime'
import 'airbnb-browser-shims/browser-only'
import 'lib/polyfill'

// okta polyfills should not be needed
//  if needed, try to find a solution to not
//  import text-encoding as a polyfill package,
//  which is not maintained (and quite big ;)
// import '@okta/okta-auth-js/polyfill'

import 'i18n'

import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'

import { appBaseUrl } from 'config/appConfig'

import App from './App'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(
  <Suspense fallback="">
    <BrowserRouter basename={appBaseUrl}>
      <App />
    </BrowserRouter>
  </Suspense>
)
